import { useCallback } from "react";
import { useConfig } from "./use-config";

export const useCurrency = () => {
  const { currency_symbol } = useConfig();
  const formatPrice = useCallback(
    (price?: string, hideCurrency = false) => {
      if (!currency_symbol) {
        return "";
      }
      const formattedPrice = parseFloat(price || "0").toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      );
      return `${formattedPrice} ${hideCurrency ? "" : currency_symbol}`;
    },
    [currency_symbol]
  );

  return { formatPrice, currency_symbol };
};
